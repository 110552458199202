import './EventCard.css';
import {formatDate} from '../utils/date.js';
import {storyblokEditable} from "@storyblok/react";

export const EventCard = ({blok}) => (
    <div
        className="EventCardContainer"
        {...storyblokEditable(blok)}
        onClick={() => {window.location.href='/'+blok.full_slug; }}
        >
        <img
            src={blok.content.image_url} alt="This is a cool Event"/>

        <EventCardDetails title={blok.content.name} date={blok.content.starts_at} genres={blok.content.genres}></EventCardDetails>
    </div>

);
export const EventCardDetails = ({title, date, genres}) => (
    <div className="EventCardDetails">
        <h3>{title}</h3>
        <p>{formatDate(date)}</p>
        <div className="tag-container">
            {genres.map((genre, index) => (
                <div key={index} className="tag">
                    {genre}
                </div>
            ))}
        </div>
    </div>
);

