import './Page.css';
import {StoryblokComponent, storyblokEditable} from "@storyblok/react";

const Page = ({blok}) => {
    const blok_header = blok.header.content.reference[0];
    const blok_footer = blok.footer.content.reference[0];

    return (<main {...storyblokEditable(blok)} className="wrapper">
        <StoryblokComponent blok={blok_header} key={blok_header._uid}/>
        <div className="content">
        {blok.body
            ? blok.body.map((blok) => (
                <StoryblokComponent blok={blok} key={blok._uid}/>
            ))
            : null}
        </div>
        <StoryblokComponent blok={blok_footer} key={blok_footer._uid}/>

    </main>);
};

export default Page;