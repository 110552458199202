import './Header.css';
import {storyblokEditable} from "@storyblok/react";

const Header = ({blok}) => (
    <header className="header" {...storyblokEditable(blok)}>
        <div className="ribbon"></div>
        <div className="header-content">
            <div className="title">{blok.pagetitle}</div>
            <nav className="menu">
                <ul>
                    <li><a href="/home">Concerts & Events</a></li>
                    <li><a href="/home">Festivals</a></li>
                    <li><a href="/home">Lounges</a></li>
                    <li><a href="/home">Sports</a></li>
                    <li><a href="/home">Partners</a></li>
                </ul>
            </nav>
        </div>
    </header>

);

export default Header;