import './Footer.css';
import { storyblokEditable } from "@storyblok/react";

const Footer = ({ blok }) => (
    <footer className="footer" {...storyblokEditable(blok)}>
      <div className="thin-line"></div>
      <a href={blok.legalurl.url} target="_blank" rel="noreferrer" className="imprint-link">Imprint</a>
      <div className="ribbon-footer"></div>
    </footer>
);

export default Footer;