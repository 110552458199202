import './EventHighlight.css';

import { storyblokEditable } from "@storyblok/react";
import {EventCard} from "./EventCard";

const EventHighlight = ({ blok }) => {
  return (
      <div
          {...storyblokEditable(blok)}
          className="EventHighlightRowContainer"
      >
          <h2><img className="EventHighlightRowIcon" src={blok.icon.filename} alt={blok.title} />{blok.title}</h2>
          <div className="EventHighlightRow">
              {blok.events.map((event) => (
                  <EventCard blok={event} key={event.content._uid}/>
              ))}
          </div>
      </div>
  );
};

export default EventHighlight;

