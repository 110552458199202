import './EventHighlight.css';
import { useState, useEffect } from 'react';
import { getStoryblokApi, storyblokEditable } from "@storyblok/react";
import {EventCard} from "./EventCard";

const { REACT_APP_STORYBLOK_VERSION } = process.env;

const EventHighlightQuery = ({blok}) => {
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData(blok.num_entries)
            .then((stories) => setEvents(stories))
            .catch((err) => setError(err));
    }, [blok.num_entries]);

    if (error) {
        return <div>Error loading data</div>;
    }

    return (
        <div
            {...storyblokEditable(blok)}
            className="EventHighlightRowContainer"
        >
            <h2><img className="EventHighlightRowIcon" src={blok.icon.filename} alt={blok.title}/>{blok.title}</h2>
            <div className="EventHighlightRow">
                {events.map((event) => (
                    <EventCard blok={event} key={event.content._uid}/>
                ))}
            </div>
        </div>
    );
};


export function fetchData(limit) {
    const storyblokApi = getStoryblokApi();
    return storyblokApi.getStories({
        "version": REACT_APP_STORYBLOK_VERSION,
        "content_type": "event",
        "per_page": limit,
      "page": 1,
  }).then((response) => {
      return response.data.stories;
  }).catch((error) => {
      console.log(error)
  });
}

export default EventHighlightQuery;

