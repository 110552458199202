import './App.css';
import {useStoryblok, StoryblokComponent} from "@storyblok/react";
import {useEffect, useState} from "react"

const {REACT_APP_STORYBLOK_VERSION} = process.env;

function App() {
    const [title, setTitle] = useState(null);

    useEffect(() => {
        if (title) {
            document.title = title;
        }
    }, [title]);


    let slug =
        window.location.pathname === "/"
            ? "home"
            : window.location.pathname.replace("/", "");

    const regexList = [
        /^(events)(\/\d*)$/, // Example regex
        // Add more regexes here if needed
    ];

    const final_slug = checkPathnameWithRegexes(slug, regexList);
    const story = useStoryblok(final_slug, {
        version: REACT_APP_STORYBLOK_VERSION,
        resolve_relations: ["event_highlight.events", "page.header", "page.footer"]
    });

    useEffect(() => {
        setTitle(story ? story.name : "Loading...");
    }, [story]);

    if (!story || !story.content) {
        return <div>Loading...</div>;
    }

    return <StoryblokComponent blok={story.content}></StoryblokComponent>;
}

export default App;

function checkPathnameWithRegexes(pathname, regexList) {
    for (const regex of regexList) {
        const match = pathname.match(regex);
        if (match) {
            return match[1] || pathname; // Return the first group if it exists, otherwise return the full pathname
        }
    }
    return pathname; // Return the full pathname if no regex matches
}