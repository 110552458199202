import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { storyblokInit, apiPlugin } from "@storyblok/react";

import Global from "./globals/Global";
import GlobalReference from "./globals/GlobalReference";
import Header from "./globals/Header";
import Footer from "./globals/Footer";

import Page from "./components/Page";
import Event from "./components/Event";
import EventHighlight from "./components/EventHighlight";
import EventDetails from "./components/EventDetails";
import EventHighlightQuery from "./components/EventHighlightQuery";
import {EventCard} from "./components/EventCard";

const { REACT_APP_STORYBLOK_TOKEN } = process.env;

storyblokInit({
  accessToken: REACT_APP_STORYBLOK_TOKEN,
  use: [apiPlugin],
  components: {
    page: Page,
    global: Global,
    global_reference: GlobalReference,
    header: Header,
    footer: Footer,

    event: Event,
    event_highlight: EventHighlight,
    event_card: EventCard,
    event_highlight_query: EventHighlightQuery,
    event_details: EventDetails,

  },
  apiOptions: {
    // for spaces located in the US or China:
    // region: "us" or "cn", // you need to specify the region
    region: 'eu',
    resolveNestedRelations: true,
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();