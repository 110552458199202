import {storyblokEditable, getStoryblokApi, StoryblokComponent} from "@storyblok/react";
import {useState, useEffect} from "react";
const { REACT_APP_STORYBLOK_VERSION } = process.env;

const EventDetails = ({ blok }) => {
    const [event, setEvent] = useState(null);

    useEffect(() => {
        let isMounted = true;
        const event_id = extractEventId();

        fetchData(event_id).then((event) => {
            if (isMounted) {
                setEvent(event);
            }
        }).catch((error) => console.log(error));

        return () => {
            isMounted = false;
        };
    }, []);

    if (!event) {
        return <div>Loading...</div>;
    }

    return (
        <div {...storyblokEditable(blok)}>
            <StoryblokComponent blok={event.content} key={event.content._uid} />
        </div>
    );
};
export default EventDetails;

export async function fetchData(event_id) {
  const storyblokApi = getStoryblokApi();

  return storyblokApi.getStory("events/" +event_id,{
        "version": REACT_APP_STORYBLOK_VERSION,
  }).then((response) => {
      return response.data.story;
  }).catch((error) => {
      console.log(error)
  });
};

function extractEventId() {
    const regex = /^.*\/(\d*)$/;
    const match = window.location.pathname.match(regex);
    if (match) {
        const extractedValue = parseInt(match[1], 10); // Parse the captured value to an integer
        if (!isNaN(extractedValue)) {
            return extractedValue; // Return the integer value if it's valid
        }
    }
    return null;
}

